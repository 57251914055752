var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.options
    ? _c("div", { staticClass: "dropdown" }, [
        _c(
          "div",
          {
            class: {
              "not-default": !_vm.isDefault,
            },
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.searchFilter,
                  expression: "searchFilter",
                },
              ],
              ref: "input",
              staticClass: "dropdown-input",
              style: _vm.inputStyle,
              attrs: {
                name: _vm.name,
                disabled: _vm.disabled,
                placeholder: _vm.placeholder,
              },
              domProps: { value: _vm.searchFilter },
              on: {
                blur: _vm.exit,
                focus: _vm.showOptions,
                keyup: _vm.keyMonitor,
                keydown: [
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.selectFirstResult.apply(null, arguments)
                  },
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "esc", 27, $event.key, [
                        "Esc",
                        "Escape",
                      ])
                    ) {
                      return null
                    }
                    return _vm.exit.apply(null, arguments)
                  },
                ],
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.searchFilter = $event.target.value
                },
              },
            }),
            _c("i", {
              class: {
                "fa fa-chevron-down": !_vm.optionsShown,
              },
              staticStyle: {
                position: "absolute",
                top: ".25rem",
                right: ".15rem",
                cursor: "pointer",
              },
              on: { click: _vm.showOptions },
            }),
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.optionsShown,
                expression: "optionsShown",
              },
            ],
            staticClass: "dropdown-content",
          },
          _vm._l(_vm.filteredOptions, function (option, index) {
            return _c(
              "div",
              {
                key: index,
                class: {
                  "dropdown-item": true,
                  active: index === 0,
                },
                on: {
                  mousedown: function ($event) {
                    return _vm.selectOption(option)
                  },
                },
              },
              [_vm._v(" " + _vm._s(option.name || option.id || "-") + " ")]
            )
          }),
          0
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }