var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "sidemenu-mask-dropdown",
        on: {
          click: _vm.onFolderClick,
          mouseover: function ($event) {
            return _vm.ellipsis($event, true)
          },
          mouseleave: function ($event) {
            return _vm.ellipsis($event, false)
          },
        },
      },
      [
        _c(
          "div",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip.top-center",
                value: _vm.tooltipConfig,
                expression: "tooltipConfig",
                modifiers: { "top-center": true },
              },
            ],
            staticClass: "tooltip-hover",
            attrs: { open: false },
          },
          [
            _c("i", {
              class: [
                "status-icon fa fa-chevron-right",
                _vm.isOpen ? "open" : "closed",
              ],
            }),
            _c("i", { class: _vm.getIcon(_vm.item.data) && !_vm.isFolder }),
            !_vm.isOpen ? _c("i", { staticClass: "fa fa-folder" }) : _vm._e(),
            _vm.isOpen
              ? _c("i", { staticClass: "fa fa-folder-open" })
              : _vm._e(),
            _vm._v(" " + _vm._s(_vm.item.data.Label) + " "),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }