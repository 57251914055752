var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.text && _vm.text != ""
        ? _c(
            "router-link",
            {
              class: _vm.linkClass,
              style: _vm.routeStyle,
              attrs: { to: _vm.formedRoute },
            },
            [
              _c("span", [_vm._t("default")], 2),
              _c("span", { style: _vm.routeStyle }, [_vm._v(_vm._s(_vm.text))]),
            ]
          )
        : _c(
            "router-link",
            {
              class: _vm.linkClass,
              style: _vm.routeStyle,
              attrs: { to: _vm.formedRoute },
            },
            [
              _vm._t("default", function () {
                return [
                  _c("span", [_vm._t("default")], 2),
                  _c("span", [_vm._v(_vm._s(_vm.text))]),
                ]
              }),
            ],
            2
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }