var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.menuItemHasLoaded && !(_vm.hasNoChildren && _vm.hasNoYearsDocuments)
      ? _c(
          "div",
          {
            class: ["sidemenu-mask-dropdown-list"],
            attrs: {
              "data-controlled-by": _vm.menuItem.id,
              open: _vm.menuIsOpen,
              level: _vm.nextLevel,
              type: "folder",
            },
          },
          [
            _vm._l(_vm.sortedMenuItemChildren, function (childMenuItem) {
              return _c(
                "div",
                { key: childMenuItem.id },
                [
                  _c("menu-item", {
                    attrs: {
                      item: childMenuItem,
                      reportCodeCounts: _vm.reportCodeCounts,
                      open: _vm.menuIsOpen,
                      activeSite: _vm.activeSite,
                      carriers: _vm.carriers,
                      activeUser: _vm.activeUser,
                      level: _vm.nextLevel,
                    },
                    on: {
                      isLoad: function ($event) {
                        return (_vm.menuItemIsLoading = $event)
                      },
                      years: function ($event) {
                        return _vm.handleYearsEvent($event)
                      },
                    },
                  }),
                ],
                1
              )
            }),
            _vm._l(
              _vm.keysForYears(_vm.menuItemDocumentsByYears),
              function (year) {
                return _c(
                  "div",
                  { key: _vm.menuItem.id + "-" + year },
                  [
                    _c("menu-item-years", {
                      attrs: {
                        item: _vm.item,
                        year: year,
                        reports: _vm.menuItemDocumentsByYears[year],
                        level: _vm.level,
                        open: _vm.menuIsOpen,
                      },
                    }),
                  ],
                  1
                )
              }
            ),
          ],
          2
        )
      : _vm._e(),
    _vm.hasNoChildren && _vm.hasNoYearsDocuments
      ? _c("div", [
          _c(
            "div",
            {
              staticClass: "sidemenu-mask-dropdown-list",
              attrs: { level: _vm.nextLevel + 2, open: "open" },
            },
            [_vm._m(0)]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sidemenu-mask-item empty" }, [
      _c("i", { staticClass: "far fa-folder-open" }),
      _vm._v(" No files found "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }