import 'es6-promise/auto'
import Vue from 'vue'
import vueHeadful from 'vue-headful'
import App from './App.vue'
import Axios from './axios'
import VueAxios from 'vue-axios'
import Router from './router'
import { store } from './store'
import VeeValidate from 'vee-validate'
import VTooltip from 'v-tooltip'
import Buefy, { ToastProgrammatic as Toast } from 'buefy'

// install tinymce dependencies globally
import 'tinymce/tinymce'
import 'tinymce/themes/silver'
import 'tinymce/plugins/code'
import 'tinymce/skins/ui/oxide/skin.min.css'
import 'tinymce/skins/ui/oxide/content.min.css'
import 'tinymce/skins/content/default/content.min.css'

import MockAdapter from './mock-adapter'

import tinymce from '@tinymce/tinymce-vue'
import papaparse from 'papaparse'
import VueAppInsights from 'vue-application-insights'
import { logout } from './vuex-actions'

import VueMoment from 'vue-moment'
import moment from 'moment-timezone'

import _ from 'underscore'
import spinLoader from './components/SpinLoader'

import AssetReportsSampleData from '../sample-data/asset-reports'
import vuescroll from 'vuescroll'
import * as crypto from 'crypto'
import Multiselect from 'vue-multiselect'
import exceptionReportApi from './api/exceptionReportApi'

// mock-data
// import annualBOLIReviewConfigMockData from './helpers/mock-data/anuual-boli-review/config'
// import annualBOLIReviewPeriodSummaryMockData from './helpers/mock-data/anuual-boli-review/period-summary'
// import annualBOLIReviewPeriodDetailMockData from './helpers/mock-data/anuual-boli-review/period-details'

export const bus = new Vue()

Vue.component('tinymce', tinymce)
Vue.component('multiselect', Multiselect)
Vue.use(require('vue-cookies'))
Vue.use(vuescroll, {
  ops: {
    vuescroll: {
      mode: 'native',
      sizeStrategy: 'percent',
      detectResize: true,
      locking: false,
      snapping: {
        enable: false,
        width: 100,
        height: 100
      },
      zooming: true
      // minZoom: 0.5
    },
    rail: {
      background: '#a0a0a0',
      keepShow: true,
      opacity: 1,
      gutterOfEnds: '0px',
      gutterOfSide: '0px',
      size: '6px'
    },
    bar: {
      onlyShowBarOnScroll: false,
      keepShow: true,
      background: '#3e577e'
    }
  }
})
Vue.use(Buefy, { defaultIconPack: 'fas' })
Vue.use(VueMoment, {
  moment
})

Vue.use(VTooltip)

Vue.component('v-popover', VTooltip.VPopover)
Vue.directive('close-popover', VTooltip.VClosePopover)
Vue.component('spin-loader', spinLoader)

const TOAST_SUCCESS_TIME = 5000
const TOAST_ERROR_TIME = 10000

/* eslint no-extend-native: "off" */

if (process.env.NODE_ENV && !process.env.NODE_ENV.startsWith('dev')) {
  Vue.use(VueAppInsights, {
    id: '97fcd282-9a33-417d-a949-64ed81235084',
    Router
  })
}

function trim (source, c) {
  if (c === ']') c = '\\]'
  if (c === '\\') c = '\\\\'
  return source.replace(new RegExp('^[' + c + ']+|[' + c + ']+$', 'g'), '')
}

// eslint-disable-next-line no-extend-native
String.prototype.replaceAll = function (search, replacement) {
  let target = this
  return target.replace(new RegExp(escapeRegExp(search), 'g'), replacement)
}

function escapeRegExp (str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

function allIndexesOf (strSrc, char) {
  let indices = []
  for (let i = 0; i < strSrc.length; i++) {
    if (strSrc[i] === char) indices.push(i)
  }
  return indices
}

// https://stackoverflow.com/a/1909508
function debounce (fn, ms) {
  let timer = 0
  return function (...args) {
    clearTimeout(timer)
    timer = setTimeout(fn.bind(this, ...args), ms || 0)
  }
}

// https://stackoverflow.com/a/1988361
Array.prototype.inArray = function (comparer) {
  for (var i = 0; i < this.length; i++) {
    if (comparer(this[i])) {
      return i
    }
  }
  return -1
}

Array.prototype.replaceOrPush = function (element, comparer) {
  const index = this.inArray(comparer)
  const isInArray = index >= 0
  if (isInArray) {
    this[index] = element
  } else {
    this.push(element)
  }
}

function parseYearOverride (year) {
  let returnVal = 0

  if (year <= 2000 && year + 2000 <= moment().year()) {
    returnVal = year + 2000
  } else if (year <= 2000 && year + 1900 <= 2000) {
    returnVal = year + 2000
  } else {
    returnVal = year
  }

  if (returnVal > ((moment().year()) + 100)) {
    returnVal -= 100
  }

  return returnVal
}

/* COPIED FROM C# */
// function parseDocumentName (fullName) {
//   fullName = fullName.replaceAll('—', '-').replaceAll('–', '-')
//   let exp = new RegExp(/([0-9]{4})-([a-zA-Z]{3})-([0-9]{2})/g)
//   let isValid = exp.test(fullName) === true

//   if (isValid) {
//     // {ext}
//     let dotLoc = fullName.lastIndexOf('.')
//     let extension = fullName.substr(dotLoc)

//     // fileName = bbbb-ttt-yy-name
//     let fileName = fullName.substr(0, dotLoc)
//     let dashIndex = allIndexesOf(fileName, '-')

//     // {bbbb}
//     let institutionRaw = fileName.substr(0, dashIndex[0])
//     let institutionId = parseInt(institutionRaw)

//     // {ttt}
//     let type = fileName.substr(dashIndex[0] + 1, dashIndex[1] - dashIndex[0] - 1)

//     // {yy}
//     let yearRaw = fileName.substr(
//       dashIndex[1] + 1,
//       dashIndex[2] - dashIndex[1] - 1
//     )

//     let year = parseInt(yearRaw)
//     // year += 2000

//     // {name}
//     let name = fileName.substr(dashIndex[2] + 1)

//     let final = {
//       institutionId: institutionId,
//       typeCode: type,
//       yearOverride: year,
//       name: name,
//       extension: extension
//     }

//     // console.log(final)

//     return final
//   } else {
//     throw new Error(`${fullName}: Name doesn't meet required format.`)
//   }
// }

/* COPIED FROM C# */
function parseReportName (fullName) {
  fullName = fullName.replaceAll('—', '-').replaceAll('–', '-')
  let exp = new RegExp(/([0-9]{4})-([a-zA-Z]{3})-([0-9]{2})/g)
  let isValid = exp.test(fullName) === true

  if (isValid) {
    // {ext}
    let dotLoc = fullName.lastIndexOf('.')
    let extension = fullName.substr(dotLoc)

    // fileName = bbbb-ttt-yy-name
    let fileName = fullName.substr(0, dotLoc)
    let dashIndex = allIndexesOf(fileName, '-')

    // {bbbb}
    let institutionRaw = fileName.substr(0, dashIndex[0])
    let institutionId = parseInt(institutionRaw)

    // {ttt}
    let type = fileName.substr(dashIndex[0] + 1, dashIndex[1] - dashIndex[0] - 1)

    // {yy}
    let yearRaw = fileName.substr(
      dashIndex[1] + 1,
      dashIndex[2] - dashIndex[1] - 1
    )

    let year = parseInt(yearRaw)
    // year += 2000

    // {name}
    let name = fileName.substr(dashIndex[2] + 1)

    let final = {
      institutionId: institutionId,
      typeCode: type,
      yearOverride: year,
      name: name,
      extension: extension
    }

    // console.log(final)

    return final
  } else {
    throw new Error(`${fullName}: Name doesn't meet required format.`)
  }
}

function getStatusNumber (status) {
  switch (status) {
    case 'unsent':
      return 0
    case 'sent':
      return 1
    case 'returned':
      return 2
  }
}

let toastDedupe = {
  timestamp: '',
  msg: ''
}

// usage: {{ file.size | prettyBytes }}
Vue.filter('prettyBytes', function (num) {
  // sourced from: https://github.com/sindresorhus/pretty-bytes
  if (typeof num !== 'number' || isNaN(num)) {
    throw new TypeError('Expected a number')
  }

  let exponent
  let unit
  let neg = num < 0
  let units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  if (neg) {
    num = -num
  }

  if (num < 1) {
    return (neg ? '-' : '') + num + ' B'
  }

  exponent = Math.min(
    Math.floor(Math.log(num) / Math.log(1000)),
    units.length - 1
  )
  num = (num / Math.pow(1000, exponent)).toFixed(2) * 1
  unit = units[exponent]

  return (neg ? '-' : '') + num + ' ' + unit
})

function newPasswordState (siteSettings, passMatch, minLength, minAlphaLower, minAlphaUpper, minNumeric, minSymbol, minUnique, twoFields) {
  let rules = [{
    isValid: minLength,
    msg: `must be at least ${siteSettings.minLength} characters long`
  },
  {
    isValid: minUnique,
    msg: `must contain at least ${siteSettings.minUnique} unique`
  },
  {
    isValid: minAlphaUpper,
    msg: `must contain at least ${siteSettings.minAlphaUpper} upper`
  },
  {
    isValid: minAlphaLower,
    msg: `must contain at least ${siteSettings.minAlphaLower} lower`
  },
  {
    isValid: minNumeric,
    msg: `must contain at least ${siteSettings.minNumeric} numbers`
  },
  {
    isValid: minSymbol,
    msg: `must contain at least ${siteSettings.minSymbol} symbols`
  }
  ]
  if (twoFields) {
    rules.push(
      {
        isValid: passMatch,
        msg: `passwords must match`
      })
  }
  return rules
}

const getFilenameFromContentDisposition = (contentDisposition) => {
  // console.log(contentDisposition)
  if (!contentDisposition) return null

  // Use a regular expression to extract the filename
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/i
  const matches = filenameRegex.exec(contentDisposition)

  if (matches != null && matches[1]) {
    // Remove quotes if they exist
    return matches[1].replace(/['"]/g, '')
  }

  return null
}

// TODO: change select drop downs to use loading indicator https://bulma.io/documentation/form/select/
Vue.mixin({
  methods: {
    deselect (selection, key) {
      selection.forEach(row => {
        row[key] = false
      })
    },
    idToPeriod (isDate = false) {
      try {
        const charset = (this.$route.params.id + '').split('')

        if (!isDate) {
          let val = `${charset.slice(0, 4).join('')}-${charset.slice(4, charset.length).join('')}`

          // if - is last char, remove it
          if (val.slice(-1) === '-') {
            val = val.slice(0, -1)
          }

          return val
        }

        return moment(`${charset.slice(0, 4).join('')}-${charset.slice(4, charset.length).join('')}-1`).add(1, 'month').subtract(1, 'day').format('YYYY-MM-DD')
      } catch (e) {
        // console.debug(e)
        return ''
      }
    },
    regenerateSelected (selected, statementDate, type, context, loaderKey, callback = () => null) {
      context[loaderKey] = true
      switch (type) {
        case 'asset-report':
          this.api().assetReports.queueReportsToGenerate({
            period: statementDate,
            institutions: [...selected.map(r => r.institutionId)],
            isRegenerating: 1
          }, _ => { context[loaderKey] = false; callback() })
          break
        case 'asset-summary-report':
          this.api().assetReports.queueReportsToGenerate({
            period: statementDate,
            institutions: [...selected.map(r => r.institutionId)],
            type: 'AssetSummaryReport',
            isRegenerating: 1
          }, _ => { context[loaderKey] = false; callback() })
          break
        case '8925-report':
          this.api().C8925Reports.queueReportsToGenerate({
            period: statementDate,
            institutions: [...selected.map(r => r.institutionId)],
            isRegenerating: 1
          }, _ => { context[loaderKey] = false; callback() })
          break
      }
    },
    keys (object) {
      if (object === null || object === undefined) {
        return []
      }

      return Object.keys(object)
    },
    moment,
    log: (...args) => null,
    parseInt,
    commaNumberDisplay (num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    $getTimezoneOffset () {
      let date = new Date()
      const offset = date.getTimezoneOffset()
      const hours = Math.floor(Math.abs(offset) / 60)
      const minutes = Math.abs(offset) % 60

      return parseInt(`${offset < 0 ? '+' : '-'}${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`)
    },
    $formatDateInLocalTimezone (date, format) {
      return moment(date).add(this.$getTimezoneOffset(), 'hours').format(format)
    },
    $inputColorScale (inputLength, scales) {
      let color = ''

      scales.map(scale => {
        if (inputLength >= scale.min && inputLength < scale.max) {
          color = scale.color
        }
      })

      return color
    },
    $hasPermissions: function (session, permissions, accessLevel = 1) {
      if (!session.permissions || session.permissions.length === 0) {
        return false
      }

      let hasPermissions = false
      session.permissions.map(permission => {
        if (permissions.includes(permission.code) && parseInt(permission.access) >= parseInt(accessLevel)) {
          hasPermissions = true
        }
      })

      return hasPermissions
    },
    checkbox (element, state) {
      element.checked = state
    },
    isEmail (email) {
      return String(email).toLowerCase()
        .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    },
    clone (source, deep) {
      var _new, prop, type

      if (source !== null) {
        if (typeof source !== 'object') {
          // What do to with functions, throw an error?
          _new = source
          return _new
        }

        _new = new source.constructor()

        for (prop in source) {
          if (source.hasOwnProperty(prop)) {
            type = typeof source[prop]

            if (deep && type === 'object') {
              _new[prop] = this.clone(source[prop])
            } else {
              _new[prop] = source[prop]
            }
          }
        }
      }

      return _new
    },
    element_clicker (element) {
      const inputs = document.querySelectorAll(element)

      if (inputs.length !== 0) {
        const input = inputs[0]
        var evt = document.createEvent('MouseEvent')
        evt.initEvent('click', true, false)
        input.dispatchEvent(evt)
      }
    },
    getUploadDate (date) {
      return moment(date).utc()
    },
    csvToJson (csvText, headers) {
      let obj = papaparse.parse(csvText)
      return JSON.stringify(obj.data.slice(1))
    },
    downloadCSV (arrayOfObjects, filename = 'export.csv') {
      const keys = Object.keys(arrayOfObjects[0])
      const header = keys.join(',')

      const rows = arrayOfObjects.map(obj => {
        return keys.map(key => obj[key]).join(',')
      })

      const csv = [header, ...rows].join('\n')

      const blob = new Blob([csv], { type: 'text/csv' })

      const a = document.createElement('a')
      a.href = URL.createObjectURL(blob)
      a.download = filename

      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    },
    $sha256 (object, isString = false) {
      if (isString) {
        return crypto.createHash('sha256').update(object).digest('hex')
      }

      return crypto.createHash('sha256').update(JSON.stringify(object)).digest('hex')
    },
    api () {
      let that = this

      const shouldUseSampleData = {
        assetReports: false
      }

      return {
        async download (route) {
          let _response = null
          try {
            let response = await that.axios({
              url: route,
              method: 'GET',
              responseType: 'blob'
            })

            if (parseInt(response.status) >= 200 && parseInt(response.status) < 300) {
              const type = response.headers['content-type']
              const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
              const link = document.createElement('a')
              link.href = window.URL.createObjectURL(blob)
              link.download = getFilenameFromContentDisposition(response.headers['content-disposition'])
              link.click()
              _response = response.data
            }
          } catch (error) {
            _response = error
          }

          return _response
        },
        annualBOLIReview: {
          async SaveConfiguration (data, callback = () => {}) {
            let result = await that.axios.post('/annual-boli-review/settings', data)

            if (callback) { callback(null, result); return }
            return result
          },
          async getConfiguration (reportingPeriod, callback = () => {}) {
            // eslint-disable-next-line no-unreachable
            let result = await that.axios.get('/annual-boli-review/' + reportingPeriod + '/settings')

            if (callback) { callback(null, result) }
            return result
          },
          async getBatches (reportingPeriod, callback = () => {}) {
            let result = await that.axios.get('/annual-boli-review' + (reportingPeriod !== null && reportingPeriod !== '' ? '?year=' + reportingPeriod : ''), {
              query: {
                year: reportingPeriod
              }
            })

            if (callback) { callback(null, result); return }
            return result
          },
          async listAssetReportsInBatch (options, _callback) {
            const { take, skip, sort, desc, batchId, reviewName } = options
            let _response = null
            let _error = null

            try {
              let response = await that.axios.get(
                `/annual-boli-review/${batchId}/reports?take=${take}&skip=${skip}&sort=${sort}&desc=${desc.includes('desc')}&reportingPeriod=${batchId}&reportName=${reviewName}`.replaceAll('undefined', '')
              )

              if (parseInt(response.status) >= 200 && parseInt(response.status) < 300) {
                _response = response.data
              }
            } catch (error) {
              _response = error
            }

            _callback(_error, _response)
          },
          async getClients (reportingPeriod, callback = null) {
            let result = await that.axios.get('/annual-boli-review/clients?reportingPeriod=' + reportingPeriod)

            if (callback) { callback(null, result); return }
            return result
          },
          async queueReportsToGenerate (options, _callback) {
            const { period, institutions, isRegenerating } = options

            let _response = null
            let _error = null

            // Ignore the sort and desc options for now
            // and just return some sample data to work with.
            if (shouldUseSampleData.assetReports) {
              _callback(null, AssetReportsSampleData['post']['/asset-reports'])
              return
            }

            try {
              let response = await that.axios.post('/annual-boli-review', {
                period,
                institutions,
                isRegenerating
              })

              if (parseInt(response.status) >= 200 && parseInt(response.status) < 300) {
                _response = response.data
              }
            } catch (error) {
              _response = error
            }

            _callback(_error, _response)
          },
          async deleteReport (options, _callback) {
            const { reportId } = options

            let _response = null
            let _error = null

            // Ignore the sort and desc options for now
            // and just return some sample data to work with.
            if (shouldUseSampleData.assetReports) {
              _callback(null, AssetReportsSampleData['delete']['/asset-reports/:batch_id/reports/:report_id'])
              return
            }

            try {
              let response = await that.axios.delete(`/annual-boli-review/0/reports/${reportId}`)

              if (parseInt(response.status) >= 200 && parseInt(response.status) < 300) {
                _response = response.data
              }
            } catch (error) {
              _response = error
            }

            _callback(_error, _response)
          },
          async getAssetReportInBatchDetail (options, _callback) {
            const { batchId, reportId, reportType } = options

            let _response = null
            let _error = null

            try {
              let response = await that.axios.get(`/annual-boli-review/${batchId}/reports/${reportId}?reportType=${reportType}`)

              if (parseInt(response.status) >= 200 && parseInt(response.status) < 300) {
                _response = response.data
              }
            } catch (error) {
              _response = error
            }

            _callback(_error, _response)
          },
          async previewReport (options, _callback) {
            const { batchId, reportIds, format, reportType } = options

            let _response = null
            let _error = null

            try {
              let params = new URLSearchParams()
              if (reportIds && reportIds.length > 0) params.append('reportIds', reportIds.join(','))
              if (format) params.append('format', format)
              if (reportType) params.append('reportType', reportType)
              let path = `/annual-boli-review/${batchId}/download?` + params.toString()
              return path
              // // let response = await that.axios.get(path)
              // let response = await that.axios({
              //   url: path,
              //   method: 'GET',
              //   responseType: 'blob'
              // })

              // if (parseInt(response.status) >= 200 && parseInt(response.status) < 300) {
              //   return response.data
              // }
            } catch (error) {
              _response = error
            }

            _callback(_error, _response)
          },
          async downloadReport (options, _callback) {
            const { batchId, reportIds, format, reportType } = options

            let _response = null
            let _error = null

            try {
              let params = new URLSearchParams()
              if (reportIds && reportIds.length > 0) params.append('reportIds', reportIds.join(','))
              if (format) params.append('format', format)
              if (reportType) params.append('reportType', reportType)
              let path = `/annual-boli-review/${batchId}/download?` + params.toString()

              // let response = await that.axios.get(path)
              let response = await that.axios({
                url: path,
                method: 'GET',
                responseType: 'blob'
              })

              if (parseInt(response.status) >= 200 && parseInt(response.status) < 300) {
                const type = response.headers['content-type']
                const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = response.headers['content-disposition'].split('filename=')[1]
                link.click()
                _response = response.data
              }
            } catch (error) {
              _response = error
            }

            _callback(_error, _response)
          }
        },
        annualQuestionnaire: {
          async getDefaults (callback = null) {
            let result = await that.axios.get('/annual-questionnaire/defaults')

            if (callback) { callback(null, result); return }
            return result
          },
          async updateDefaults (data, callback = null) {
            let result = await that.axios.post('/annual-questionnaire/defaults', data)

            if (callback) { callback(null, result); return }
            return result
          },
          async getClients (year, callback = null) {
            let result = await that.axios.get('/annual-questionnaire/clients?year=' + year)

            if (callback) { callback(null, result); return }
            return result
          },
          async getBatches (reportingPeriod = null, callback = null) {
            let result = await that.axios.get('/annual-questionnaire' + (reportingPeriod !== null && reportingPeriod !== '' ? '?year=' + reportingPeriod : ''), {
              query: {
                year: reportingPeriod
              }
            })

            if (callback) { callback(null, result); return }
            return result
          },
          async listAssetReportBatches (options, _callback) {
            const { take, skip, sort, desc, reportingPeriod } = options

            let _response = null
            let _error = null

            try {
              let response = await that.axios.get(
                `/annual-questionnaire/${reportingPeriod}?take=${take}&skip=${skip}&sort=${sort}&desc=${desc.includes('desc')}&reportingPeriod=${reportingPeriod}`
              )

              if (parseInt(response.status) >= 200 && parseInt(response.status) < 300) {
                _response = response.data
              }
            } catch (error) {
              _response = error
            }

            _callback(_error, _response)
          },
          async listAssetReportsInBatch (options, _callback) {
            const { batchId, take, skip, sort, desc, createdAt, questionnaireName, status } = options

            let _response = null
            let _error = null
            let createdAtFilter = ''

            if (createdAt !== undefined) {
              createdAtFilter = createdAt
              if (createdAt.match(/\d{2}\/\d{2}/gm)) {
                let parts = createdAt.split('/')
                createdAtFilter = `${parts[0]}-${parts[1]}`
              }
              if (createdAt.match(/\d{2}\/\d{4}/gm)) {
                let parts = createdAt.split('/')
                createdAtFilter = `${parts[1]}-${parts[0]}`
              }
              if (createdAt.match(/\d{2}\/\d{2}\/\d{2,4}/gm)) {
                let parts = createdAt.split('/')
                createdAtFilter = `${parts[2]}-${parts[0]}-${parts[1]}`
              }
            }

            try {
              let response = await that.axios.get(
                `/annual-questionnaire/${batchId}?take=${take}&skip=${skip}&sort=${sort}&desc=${desc.includes('desc')}&status=${getStatusNumber(status)}&questionnaireName=${questionnaireName}&createdAt=${createdAtFilter}`.replaceAll('undefined', '')
              )

              if (parseInt(response.status) >= 200 && parseInt(response.status) < 300) {
                _response = response.data
              }
            } catch (error) {
              _response = error
            }

            _callback(_error, _response)
          },
          async getCurrentQuestionnaireForInstitution (options, _callback) {
            /**
             * Used to get the currently unfinished questionnaire for an institution.
             * However, if questionnaires are present from past years and are not complete this
             * should also retrieve those. Though this is an edge case we should likely handle
             * it. Considering that they can ghenerate for any year and institution.
             */
            const { institutionId } = options

            let _response = null
            let _error = null

            try {
              let response = await that.axios.get(`/annual-questionnaire/institution/${institutionId}`)

              if (parseInt(response.status) >= 200 && parseInt(response.status) < 300) {
                _response = response.data
              }
            } catch (error) {
              _response = error
            }

            _callback(_error, _response)
          },
          async getAssetReportInBatchDetail (options, _callback) {
            const { batchId, reportId } = options

            let _response = null
            let _error = null

            try {
              let response = await that.axios.get(`/annual-questionnaire/${batchId}/reports/${reportId}`)

              if (parseInt(response.status) >= 200 && parseInt(response.status) < 300) {
                _response = response.data
              }
            } catch (error) {
              _response = error
            }

            _callback(_error, _response)
          },
          async getInstitutionsForReportingPeriod (options, _callback) {
            let { reportingPeriod, type } = options

            if (!type) {
              type = 'AssetReport'
            }

            let _response = null
            let _error = null

            try {
              let response = await that.axios.get(`/annual-questionnaire/institutions?period=${reportingPeriod}&ReportType=${type}`)
              if (parseInt(response.status) >= 200 && parseInt(response.status) < 300) {
                _response = response.data
              }
            } catch (error) {
              _response = error
            }

            _callback(_error, _response)
          },
          async queueReportsToGenerate (options, _callback) {
            const { period, institutions, isRegenerating } = options

            let _response = null
            let _error = null

            // Ignore the sort and desc options for now
            // and just return some sample data to work with.
            if (shouldUseSampleData.assetReports) {
              _callback(null, AssetReportsSampleData['post']['/asset-reports'])
              return
            }

            try {
              let response = await that.axios.post('/annual-questionnaire', {
                period,
                institutions,
                isRegenerating
              })

              if (parseInt(response.status) >= 200 && parseInt(response.status) < 300) {
                _response = response.data
              }
            } catch (error) {
              _response = error
            }

            _callback(_error, _response)
          },
          async previewReport (options, _callback) {
            const { batchId, reportIds, format } = options

            let _response = null
            let _error = null

            // Ignore the sort and desc options for now
            // and just return some sample data to work with.
            if (shouldUseSampleData.assetReports) {
              _callback(null, AssetReportsSampleData['get']['/asset-reports/:batch_id/download'])
              return
            }

            try {
              let params = new URLSearchParams()
              if (reportIds && reportIds.length > 0) params.append('reportIds', reportIds.join(','))
              if (format) params.append('format', format)
              let path = `/8925-reports/${batchId}/download?` + params.toString()
              return path
              // // let response = await that.axios.get(path)
              // let response = await that.axios({
              //   url: path,
              //   method: 'GET',
              //   responseType: 'blob'
              // })

              // if (parseInt(response.status) >= 200 && parseInt(response.status) < 300) {
              //   return response.data
              // }
            } catch (error) {
              _response = error
            }

            _callback(_error, _response)
          },
          async downloadReport (options, _callback) {
            const { batchId, reportIds, format } = options

            let _response = null
            let _error = null

            // Ignore the sort and desc options for now
            // and just return some sample data to work with.
            if (shouldUseSampleData.assetReports) {
              _callback(null, AssetReportsSampleData['get']['/asset-reports/:batch_id/download'])
              return
            }

            try {
              let params = new URLSearchParams()
              if (reportIds && reportIds.length > 0) params.append('reportIds', reportIds.join(','))
              if (format) params.append('format', format)
              let path = `/8925-reports/${batchId}${format === 'xlsx' ? '/xlsx' : ''}/download?` + params.toString()

              // let response = await that.axios.get(path)
              let response = await that.axios({
                url: path,
                method: 'GET',
                responseType: 'blob'
              })

              if (parseInt(response.status) >= 200 && parseInt(response.status) < 300) {
                const type = response.headers['content-type']
                const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = response.headers['content-disposition'].split('filename=')[1]
                link.click()
                _response = response.data
              }
            } catch (error) {
              _response = error
            }

            _callback(_error, _response)
          },
          async deleteReport (options, _callback) {
            const { reportId } = options

            let _response = null
            let _error = null

            // Ignore the sort and desc options for now
            // and just return some sample data to work with.
            if (shouldUseSampleData.assetReports) {
              _callback(null, AssetReportsSampleData['delete']['/asset-reports/:batch_id/reports/:report_id'])
              return
            }

            try {
              let response = await that.axios.delete(`/annual-questionnaire/${reportId}`)

              if (parseInt(response.status) >= 200 && parseInt(response.status) < 300) {
                _response = response.data
              }
            } catch (error) {
              _response = error
            }

            _callback(_error, _response)
          }
        },
        etl: {
          async Salesforce (callback = null) {
            let result = await that.axios.get('/etl/Salesforce')

            if (callback) { callback(result); return }
            return result
          },
          async unjamSalesforceImport (callback = null) {
            let result = await that.axios.post('/etl/Salesforce/unjam')

            if (callback) { callback(result); return }
            return result
          },
          async requestSalesforceProcessing (callback = null) {
            let result = await that.axios.post('/etl/Salesforce')

            if (callback) { callback(result); return }
            return result
          },
          async newSalesforceImport (type, callback = null) {
            let result = await that.axios.post('/etl/Salesforce', { type })

            if (callback) { callback(result); return }
            return result
          },
          async Jobs (search = '', filter = '', page = 0, results = 10, callback = null) {
            let result = await that.axios.get('/etl/jobs?search=' + search + '&filter=' + filter + '&page=' + page + '&results=' + results)

            if (callback) { callback(result); return }
            return result
          },
          async unjam (callback = null) {
            let result = await that.axios.post('/etl/unjam')

            if (callback) { callback(result); return }
            return result
          }
        },
        permissions () {
          return {
            async createPermissionTemplate (permissionTemplate) {
              let response = await that.axios.post('/permission-templates', permissionTemplate)
              try {
                return JSON.parse(response).response
              } catch (error) {
                return response
              }
            },
            async getPermissionTemplate (permissionTemplateId) {
              let response = await that.axios.get(`/permission-templates/${permissionTemplateId}`)
              try {
                return JSON.parse(response).response
              } catch (error) {
                return response
              }
            },
            async deletePermissionTemplates (permissionTemplateIds) {
              let response = await that.axios.delete(`/permission-templates/${permissionTemplateIds}`)
              try {
                return JSON.parse(response).response
              } catch (error) {
                return response
              }
            },
            async getPermissionTemplates (take, skip) {
              let params = new URLSearchParams()
              if (!isNaN(take)) {
                params.append('take', take)
              }

              if (!isNaN(skip)) {
                params.append('skip', skip)
              }

              let response = await that.axios.get(`/permission-templates?${params.toString()}`)
              try {
                return JSON.parse(response).response
              } catch (error) {
                return response
              }
            },
            getUserPermissions (userId) {
              let response = that.axios.get(`/users/${userId}/permissions`)

              try {
                return JSON.parse(response).response
              } catch (error) {
                return response
              }
            },
            setUserPermissions (userId, permissions) {
              let response = that.axios.put(`/users/${userId}/permissions`, permissions)

              try {
                return JSON.parse(response).response
              } catch (error) {
                return response
              }
            }
          }
        },
        async checkEmail (email) {
          let _response = null
          let _error = null

          try {
            let response = await that.axios.get('/users/' + btoa(email) + '/sites')

            if (typeof response === 'string') {
              response = JSON.parse(response)
            }

            if (parseInt(response.status) >= 200 && parseInt(response.status) < 300) {
              _response = { sites: [...response.data], status: parseInt(response.status) }
            } else {
              _response = { status: parseInt(response.status) }
            }
          } catch (error) {
            _error = error
          }

          return {
            error: _error,
            response: _response
          }
        },
        async getInstitutionCounts (institutions) {
          let counts = []

          for (let i = 0; i < institutions.length; i++) {
            const _institution = institutions[i]

            let _response = null
            let _error = null

            try {
              let response = await that.axios.get(`/institutions/${_institution}/related-data`)

              if (parseInt(response.status) >= 200 && parseInt(response.status) < 300) {
                _response = response.data
              }
            } catch (error) {
              _error = error
            }

            counts.push({
              id: _institution,
              response: _response,
              error: _error
            })
          }

          return counts
        },
        sms: {
          async verifyPhoneNumber (phoneNumber, __callback) {
            let _response = null
            let _error = null

            try {
              let response = await that.axios.get('/mfa/verify/' + phoneNumber)
              if (parseInt(response.status) >= 200 && parseInt(response.status) < 300) {
                _response = true
              } else {
                _response = false
              }
            } catch (error) {
              _error = error
              _response = false
            }

            __callback(_response, _error)
          }
        },
        async getUserInfo (userId, _callback) {
          let _response = null
          let _error = null

          try {
            let response = await that.axios.get('/users/' + userId)

            if (parseInt(response.status) >= 200 && parseInt(response.status) < 300) {
              _response = response.data
            }
          } catch (error) {
            _error = error
          }

          _callback(_response, _error)
        },
        async getMembershipsData (userId, _callback) {
          let _response = null
          let _error = null

          try {
            let response = await that.axios.get('/memberships/' + userId)

            if (parseInt(response.status) >= 200 && parseInt(response.status) < 300) {
              _response = response.data
            }
          } catch (error) {
            _error = error
          }

          _callback(_response, _error)
        },
        async getInstitutions (_callback) {
          let _response = null
          let _error = null

          try {
            let response = await that.axios.get('/institutions')

            if (parseInt(response.status) >= 200 && parseInt(response.status) < 300) {
              _response = response.data
            }
          } catch (error) {
            _error = error
          }

          _callback(_response, _error)
        },
        states: {
          async create (input, _callback) {
            let _response = null
            let _error = null

            try {
              let response = await that.axios.post('/report-configuration/states', input)

              if (parseInt(response.status) >= 200 && parseInt(response.status) < 300) {
                _response = response.data
              }
            } catch (error) {
              _error = error
            }

            _callback({
              response: _response,
              error: _error
            })
          },
          async get (id, _callback) {
            let _response = null
            let _error = null

            try {
              let response = await that.axios.get('/report-configuration/states/' + id)

              if (parseInt(response.status) >= 200 && parseInt(response.status) < 300) {
                _response = response.data
              }
            } catch (error) {
              _error = error
            }

            _callback({
              response: _response,
              error: _error
            })
          },
          async update (id, input, _callback) {
            let _response = null
            let _error = null

            try {
              let response = await that.axios.patch('/report-configuration/states/' + id, input)

              if (parseInt(response.status) >= 200 && parseInt(response.status) < 300) {
                _response = response.data
              }
            } catch (error) {
              _error = error
            }

            _callback({
              response: _response,
              error: _error
            })
          }
        },
        async getStates (_callback) {
          let _response = null
          let _error = null

          try {
            let response = await that.axios.get('/report-configuration/states')

            if (parseInt(response.status) >= 200 && parseInt(response.status) < 300) {
              _response = response.data
            }
          } catch (error) {
            _error = error
          }

          _callback(_response, _error)
        },
        charts () {
          return {
            async getAssetSummary (clientId, _callback) {
              let _response = null
              let _error = null

              try {
                let response = await that.axios.get(
                  encodeURI(`/charts/carrier-summary?clientId=${clientId}`)
                )

                if (parseInt(response.status) >= 200 && parseInt(response.status) < 300) {
                  _response = response.data
                }
              } catch (error) {
                _error = error
              }

              _callback(_response, _error)
            },
            async boli (clientId, _callback) {
              let _response = null
              let _error = null

              try {
                let response = await that.axios.get(
                  encodeURI(`/charts/boli?clientId=${clientId}`)
                )

                if (parseInt(response.status) >= 200 && parseInt(response.status) < 300) {
                  _response = response.data
                }
              } catch (error) {
                _error = error
              }

              _callback(_response, _error)
            },
            async credit (clientId, _callback) {
              let _response = null
              let _error = null

              try {
                let response = await that.axios.get('/charts/credit?clientId=' + clientId)

                if (parseInt(response.status) >= 200 && parseInt(response.status) < 300) {
                  _response = response.data
                }
              } catch (error) {
                _error = error
              }

              _callback(_response, _error)
            }
          }
        },
        async deleteMFA (userId, _callback) {
          let _response = null
          let _error = null

          try {
            let response = await that.axios.delete('/mfa/' + userId)

            if (parseInt(response.status) >= 200 && parseInt(response.status) < 300) {
              _response = true
            }
          } catch (error) {
            _error = error
          }

          _callback(_response, _error)
        },
        async getAnnouncements (data, _callback) {
          let _response = null
          let _error = null

          try {
            let response = await that.axios.post(`/announcements/search`, data)

            if (parseInt(response.status) >= 200 && parseInt(response.status) < 300) {
              _response = response.data
            }
          } catch (error) {
            _error = error
          }

          _callback(_response, _error)
        },
        async getActiveAnnouncements (_callback) {
          let _response = null
          let _error = null

          try {
            let response = await that.axios.get(`/announcements/active`)

            if (parseInt(response.status) >= 200 && parseInt(response.status) < 300) {
              _response = response.data
            }
          } catch (error) {
            _error = error
          }

          _callback(_response, _error)
        },
        async getAnnouncement (id, _callback) {
          let _response = null
          let _error = null

          try {
            let response = await that.axios.get(`/announcements/${id}`)

            if (parseInt(response.status) >= 200 && parseInt(response.status) < 300) {
              _response = response.data
            }
          } catch (error) {
            _error = error
          }

          _callback(_response, _error)
        },
        async deleteAnnouncement (id, _callback) {
          let _response = null
          let _error = null

          try {
            let response = await that.axios.delete(`/announcements/${id}`)

            if (parseInt(response.status) >= 200 && parseInt(response.status) < 300) {
              _response = response.data
            }
          } catch (error) {
            _error = error
          }

          _callback(_response, _error)
        },
        assetReports: {
          async listAssetReportBatches (options, _callback) {
            let { take, skip, sort, desc, batchName, type } = options

            if (!type) {
              type = 'AssetReport'
            }

            let _response = null
            let _error = null

            // Ignore the sort and desc options for now
            // and just return some sample data to work with.
            if (shouldUseSampleData.assetReports) {
              _callback(null, AssetReportsSampleData['get']['/asset-reports'].data)
              return
            }

            try {
              let response = await that.axios.get(
                `/asset-reports?take=${take}&skip=${skip}&sort=${sort}&desc=${desc.includes('desc')}&batchName=${batchName}&ReportType=${type}`
              )

              if (parseInt(response.status) >= 200 && parseInt(response.status) < 300) {
                _response = response.data
              }
            } catch (error) {
              _response = error
            }

            _callback(_error, _response)
          },
          async listAssetReportsInBatch (options, _callback) {
            const { batchId, batchName, take, skip, sort, desc, createdAt, type } = options

            let _response = null
            let _error = null
            let createdAtFilter = ''

            if (createdAt !== undefined) {
              createdAtFilter = createdAt
              if (createdAt.match(/\d{2}\/\d{2}/gm)) {
                let parts = createdAt.split('/')
                createdAtFilter = `${parts[0]}-${parts[1]}`
              }
              if (createdAt.match(/\d{2}\/\d{4}/gm)) {
                let parts = createdAt.split('/')
                createdAtFilter = `${parts[1]}-${parts[0]}`
              }
              if (createdAt.match(/\d{2}\/\d{2}\/\d{2,4}/gm)) {
                let parts = createdAt.split('/')
                createdAtFilter = `${parts[2]}-${parts[0]}-${parts[1]}`
              }
            }

            // Ignore the sort and desc options for now
            // and just return some sample data to work with.
            if (shouldUseSampleData.assetReports) {
              _callback(null, AssetReportsSampleData['get']['/asset-reports/:batch_id/reports'].data)
              return
            }

            try {
              let response = await that.axios.get(
                `/asset-reports/${batchId}/reports?take=${take}&skip=${skip}&sort=${sort}&desc=${desc.includes('desc')}&createdAt=${createdAtFilter}&reportName=${batchName !== '' && batchName !== undefined ? batchName.replace('undefined', '') : ''}&ReportType=${type}`
              )

              if (parseInt(response.status) >= 200 && parseInt(response.status) < 300) {
                _response = response.data
              }
            } catch (error) {
              _response = error
            }

            _callback(_error, _response)
          },
          async getAssetReportInBatchDetail (options, _callback) {
            const { batchId, reportId } = options

            let _response = null
            let _error = null

            // Ignore the sort and desc options for now
            // and just return some sample data to work with.
            if (shouldUseSampleData.assetReports) {
              _callback(null, AssetReportsSampleData['get']['/asset-reports/:batch_id/reports/:report_id'])
              return
            }

            try {
              let response = await that.axios.get(`/asset-reports/${batchId}/reports/${reportId}`)

              if (parseInt(response.status) >= 200 && parseInt(response.status) < 300) {
                _response = response.data
              }
            } catch (error) {
              _response = error
            }

            _callback(_error, _response)
          },
          async getInstitutionsForReportingPeriod (options, _callback) {
            let { reportingPeriod, type } = options

            if (!type) {
              type = 'AssetReport'
            }

            let _response = null
            let _error = null

            // Ignore the sort and desc options for now
            // and just return some sample data to work with.
            if (shouldUseSampleData.assetReports) {
              _callback(null, AssetReportsSampleData['get']['/asset-reports/institutions'])
              return
            }

            try {
              let response = await that.axios.get(`/asset-reports/institutions?period=${moment(reportingPeriod).format('YYYY-MM-DD')}&ReportType=${type}`)
              if (parseInt(response.status) >= 200 && parseInt(response.status) < 300) {
                _response = response.data
              }
            } catch (error) {
              _response = error
            }

            _callback(_error, _response)
          },
          async queueReportsToGenerate (options, _callback) {
            let { period, institutions, isRegenerating, type } = options

            if (!type) {
              type = 'AssetReport'
            }

            let _response = null
            let _error = null

            // Ignore the sort and desc options for now
            // and just return some sample data to work with.
            if (shouldUseSampleData.assetReports) {
              _callback(null, AssetReportsSampleData['post']['/asset-reports'])
              return
            }

            try {
              let response = await that.axios.post('/asset-reports', {
                period,
                isRegenerating,
                institutions,
                ReportType: type
              })

              if (parseInt(response.status) >= 200 && parseInt(response.status) < 300) {
                _response = response.data
              }
            } catch (error) {
              _response = error
            }

            _callback(_error, _response)
          },
          async previewReport (options, _callback) {
            const { batchId, reportIds, format } = options

            let _response = null
            let _error = null

            // Ignore the sort and desc options for now
            // and just return some sample data to work with.
            if (shouldUseSampleData.assetReports) {
              _callback(null, AssetReportsSampleData['get']['/asset-reports/:batch_id/download'])
              return
            }

            try {
              let params = new URLSearchParams()
              if (reportIds && reportIds.length > 0) params.append('reportIds', reportIds.join(','))
              if (format) params.append('format', format)
              let path = `/asset-reports/${batchId}/download?` + params.toString()
              return path
              // // let response = await that.axios.get(path)
              // let response = await that.axios({
              //   url: path,
              //   method: 'GET',
              //   responseType: 'blob'
              // })

              // if (parseInt(response.status) >= 200 && parseInt(response.status) < 300) {
              //   return response.data
              // }
            } catch (error) {
              _response = error
            }

            _callback(_error, _response)
          },
          async downloadReport (options, _callback) {
            const { batchId, reportIds, format } = options

            let _response = null
            let _error = null

            // Ignore the sort and desc options for now
            // and just return some sample data to work with.
            if (shouldUseSampleData.assetReports) {
              _callback(null, AssetReportsSampleData['get']['/asset-reports/:batch_id/download'])
              return
            }

            try {
              let params = new URLSearchParams()
              if (reportIds && reportIds.length > 0) params.append('reportIds', reportIds.join(','))
              if (format) params.append('format', format)
              let path = `/asset-reports/${batchId}${format === 'xlsx' ? '/xlsx' : ''}/download?` + params.toString()

              // let response = await that.axios.get(path)
              let response = await that.axios({
                url: path,
                method: 'GET',
                responseType: 'blob'
              })

              if (parseInt(response.status) >= 200 && parseInt(response.status) < 300) {
                const type = response.headers['content-type']
                const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = response.headers['content-disposition'].split('filename=')[1]
                link.click()
                _response = response.data
              }
            } catch (error) {
              _response = error
            }

            _callback(_error, _response)
          },
          async deleteReport (options, _callback) {
            const { batchId, reportId } = options

            let _response = null
            let _error = null

            // Ignore the sort and desc options for now
            // and just return some sample data to work with.
            if (shouldUseSampleData.assetReports) {
              _callback(null, AssetReportsSampleData['delete']['/asset-reports/:batch_id/reports/:report_id'])
              return
            }

            try {
              let response = await that.axios.delete(`/asset-reports/${batchId}/reports/${reportId}`)

              if (parseInt(response.status) >= 200 && parseInt(response.status) < 300) {
                _response = response.data
              }
            } catch (error) {
              _response = error
            }

            _callback(_error, _response)
          }
        },
        C8925Reports: {
          async listAssetReportBatches (options, _callback) {
            const { take, skip, sort, desc, batchName } = options

            let _response = null
            let _error = null

            // Ignore the sort and desc options for now
            // and just return some sample data to work with.
            if (shouldUseSampleData.assetReports) {
              _callback(null, AssetReportsSampleData['get']['/asset-reports'].data)
              return
            }

            try {
              let response = await that.axios.get(
                `/8925-reports?take=${take}&skip=${skip}&sort=${sort}&desc=${desc.includes('desc')}&batchName=${batchName}`
              )

              if (parseInt(response.status) >= 200 && parseInt(response.status) < 300) {
                _response = response.data
              }
            } catch (error) {
              _response = error
            }

            _callback(_error, _response)
          },
          async listAssetReportsInBatch (options, _callback) {
            const { batchId, batchName, take, skip, sort, desc, createdAt } = options

            let _response = null
            let _error = null
            let createdAtFilter = ''

            if (createdAt !== undefined) {
              createdAtFilter = createdAt
              if (createdAt.match(/\d{2}\/\d{2}/gm)) {
                let parts = createdAt.split('/')
                createdAtFilter = `${parts[0]}-${parts[1]}`
              }
              if (createdAt.match(/\d{2}\/\d{4}/gm)) {
                let parts = createdAt.split('/')
                createdAtFilter = `${parts[1]}-${parts[0]}`
              }
              if (createdAt.match(/\d{2}\/\d{2}\/\d{2,4}/gm)) {
                let parts = createdAt.split('/')
                createdAtFilter = `${parts[2]}-${parts[0]}-${parts[1]}`
              }
            }

            // Ignore the sort and desc options for now
            // and just return some sample data to work with.
            if (shouldUseSampleData.assetReports) {
              _callback(null, AssetReportsSampleData['get']['/asset-reports/:batch_id/reports'].data)
              return
            }

            try {
              let response = await that.axios.get(
                `/8925-reports/${batchId}/reports?take=${take}&skip=${skip}&sort=${sort}&desc=${desc.includes('desc')}&createdAt=${createdAtFilter}&reportName=${batchName !== '' && batchName !== undefined ? batchName.replace('undefined', '') : ''}`
              )

              if (parseInt(response.status) >= 200 && parseInt(response.status) < 300) {
                _response = response.data
              }
            } catch (error) {
              _response = error
            }

            _callback(_error, _response)
          },
          async getAssetReportInBatchDetail (options, _callback) {
            const { batchId, reportId } = options

            let _response = null
            let _error = null

            // Ignore the sort and desc options for now
            // and just return some sample data to work with.
            if (shouldUseSampleData.assetReports) {
              _callback(null, AssetReportsSampleData['get']['/asset-reports/:batch_id/reports/:report_id'])
              return
            }

            try {
              let response = await that.axios.get(`/8925-reports/${batchId}/reports/${reportId}`)

              if (parseInt(response.status) >= 200 && parseInt(response.status) < 300) {
                _response = response.data
              }
            } catch (error) {
              _response = error
            }

            _callback(_error, _response)
          },
          async getInstitutionsForReportingPeriod (options, _callback) {
            let { reportingPeriod, type } = options

            if (!type) {
              type = 'AssetReport'
            }

            let _response = null
            let _error = null

            // Ignore the sort and desc options for now
            // and just return some sample data to work with.
            if (shouldUseSampleData.assetReports) {
              _callback(null, AssetReportsSampleData['get']['/asset-reports/institutions'])
              return
            }

            try {
              let response = await that.axios.get(`/8925-reports/institutions?period=${reportingPeriod}&ReportType=${type}`)
              if (parseInt(response.status) >= 200 && parseInt(response.status) < 300) {
                _response = response.data
              }
            } catch (error) {
              _response = error
            }

            _callback(_error, _response)
          },
          async queueReportsToGenerate (options, _callback) {
            const { period, institutions, isRegenerating, reportType } = options

            let _response = null
            let _error = null

            // Ignore the sort and desc options for now
            // and just return some sample data to work with.
            if (shouldUseSampleData.assetReports) {
              _callback(null, AssetReportsSampleData['post']['/asset-reports'])
              return
            }

            try {
              let response = await that.axios.post('/8925-reports', {
                period,
                institutions,
                isRegenerating,
                reportType
              })

              if (parseInt(response.status) >= 200 && parseInt(response.status) < 300) {
                _response = response.data
              }
            } catch (error) {
              _response = error
            }

            _callback(_error, _response)
          },
          async previewReport (options, _callback) {
            const { batchId, reportIds, format } = options

            let _response = null
            let _error = null

            // Ignore the sort and desc options for now
            // and just return some sample data to work with.
            if (shouldUseSampleData.assetReports) {
              _callback(null, AssetReportsSampleData['get']['/asset-reports/:batch_id/download'])
              return
            }

            try {
              let params = new URLSearchParams()
              if (reportIds && reportIds.length > 0) params.append('reportIds', reportIds.join(','))
              if (format) params.append('format', format)
              let path = `/8925-reports/${batchId}/download?` + params.toString()
              return path
              // // let response = await that.axios.get(path)
              // let response = await that.axios({
              //   url: path,
              //   method: 'GET',
              //   responseType: 'blob'
              // })

              // if (parseInt(response.status) >= 200 && parseInt(response.status) < 300) {
              //   return response.data
              // }
            } catch (error) {
              _response = error
            }

            _callback(_error, _response)
          },
          async downloadReport (options, _callback) {
            const { batchId, reportIds, format } = options

            let _response = null
            let _error = null

            // Ignore the sort and desc options for now
            // and just return some sample data to work with.
            if (shouldUseSampleData.assetReports) {
              _callback(null, AssetReportsSampleData['get']['/asset-reports/:batch_id/download'])
              return
            }

            try {
              let params = new URLSearchParams()
              if (reportIds && reportIds.length > 0) params.append('reportIds', reportIds.join(','))
              if (format) params.append('format', format)
              let path = `/8925-reports/${batchId}${format === 'xlsx' ? '/xlsx' : ''}/download?` + params.toString()

              // let response = await that.axios.get(path)
              let response = await that.axios({
                url: path,
                method: 'GET',
                responseType: 'blob'
              })

              if (parseInt(response.status) >= 200 && parseInt(response.status) < 300) {
                const type = response.headers['content-type']
                const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = response.headers['content-disposition'].split('filename=')[1]
                link.click()
                _response = response.data
              }
            } catch (error) {
              _response = error
            }

            _callback(_error, _response)
          },
          async deleteReport (options, _callback) {
            const { batchId, reportId } = options

            let _response = null
            let _error = null

            // Ignore the sort and desc options for now
            // and just return some sample data to work with.
            if (shouldUseSampleData.assetReports) {
              _callback(null, AssetReportsSampleData['delete']['/asset-reports/:batch_id/reports/:report_id'])
              return
            }

            try {
              let response = await that.axios.delete(`/8925-reports/${batchId}/reports/${reportId}`)

              if (parseInt(response.status) >= 200 && parseInt(response.status) < 300) {
                _response = response.data
              }
            } catch (error) {
              _response = error
            }

            _callback(_error, _response)
          }
        },
        ...exceptionReportApi(that.axios)
      }
    },
    async getListHeights (anchor, rowHeight, headerHeight, elements, __callback) { // Accepts a list of element ids to parse through for heights to accommodate
      let func = () => {
        let node = document.getElementById(anchor)

        if (!node || node === null || node === undefined) {
          return
        }

        let height = node.offsetHeight
        elements.map(_element => {
          let dom = document.querySelector(_element)
          if (dom) {
            height -= dom.offsetHeight

            if (dom.style['margin-bottom']) {
              height -= parseInt(dom.style['margin-bottom'].replace('px', ''))
            }

            if (dom.style['margin-top']) {
              height -= parseInt(dom.style['margin-top'].replace('px', ''))
            }
          }
        })

        __callback([Math.floor((height - headerHeight) / rowHeight), height])
      }

      setTimeout(func.bind(this), 500)
    },
    formatDate (date, format) {
      return moment(date).utc().format(format)
    },
    formatDateNoTZ (date, format) {
      return moment(date).format(format)
    },
    formatDateInLocalTimezone (date, format) {
      return moment(date).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format(format)
    },
    getTimeZone () {
      return Intl.DateTimeFormat().resolvedOptions().timeZone
    },
    getTotals (object, field, divisor = 1) {
      let total = 0

      object.map(record => {
        total += record[field]
      })

      return total / divisor
    },
    formatMoney (amount, includeCents) {
      var formatter = new Intl.NumberFormat()

      if (includeCents) {
        // Create our number formatter.
        formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD'
        })
      } else {
        formatter = Intl.NumberFormat('en-US')
      }

      return formatter.format(amount)
    },
    sortArrayOfObjects (array, key) {
      return _.sortBy(array, key)
    },
    sortByInArray (array, name) {
      return _.sortBy(array, name)
    },
    sortBy (object, name) {
      return _.sortBy(object, name)
    },
    floor (num) {
      return Math.floor(num)
    },
    trim,
    forceFormat (event) {
      // check if input value is a number value
      let isNumericInput
      if (
        (event.keyCode >= 48 && event.keyCode <= 57) ||
        (event.keyCode >= 96 && event.keyCode <= 105)
      ) {
        isNumericInput = true
      } else {
        isNumericInput = false
      }

      // check if input value is a modifier key ex: arrowLeft, arrowRight, capslock, shift ect
      let isModifierKey
      if (
        event.shiftKey === true ||
        event.keyCode === 35 ||
        event.keyCode === 36 ||
        event.keyCode === 8 ||
        event.keyCode === 9 ||
        event.keyCode === 13 ||
        event.keyCode === 46 ||
        (event.keyCode > 36 && event.keyCode < 41) ||
        ((event.ctrlKey === true || event.metaKey === true) &&
          (event.keyCode === 65 ||
            event.keyCode === 67 ||
            event.keyCode === 86 ||
            event.keyCode === 88 ||
            event.keyCode === 90))
      ) {
        isModifierKey = true
      } else {
        isModifierKey = false
      }

      // if inputed value is not a modifier or numeric strip it
      if (!isNumericInput && !isModifierKey) {
        event.preventDefault()
      }
    },
    formatPhoneInput (event) {
      // check if input value is a modifier key ex: arrowLeft, arrowRight, capslock, shift ect
      let isModifierKey
      if (
        event.shiftKey === true ||
        event.keyCode === 35 ||
        event.keyCode === 36 ||
        event.keyCode === 8 ||
        event.keyCode === 9 ||
        event.keyCode === 13 ||
        event.keyCode === 46 ||
        (event.keyCode > 36 && event.keyCode < 41) ||
        ((event.ctrlKey === true || event.metaKey === true) &&
          (event.keyCode === 65 ||
            event.keyCode === 67 ||
            event.keyCode === 86 ||
            event.keyCode === 88 ||
            event.keyCode === 90))
      ) {
        isModifierKey = true
      } else {
        isModifierKey = false
      }

      // dont change anything if modifier key inputed
      // eslint-disable-next-line block-spacing
      if (isModifierKey) {
        return
      }

      // get the input element and auto format value to (000) 000-0000
      const el = event.target
      const userInput = el.value.replace(/\D/g, '').substring(0, 10)
      const zip = userInput.substring(0, 3)
      const middle = userInput.substring(3, 6)
      const last = userInput.substring(6, 10)

      if (userInput.length > 6) {
        el.value = `(${zip}) ${middle}-${last}`
      } else if (userInput.length > 3) {
        el.value = `(${zip}) ${middle}`
      } else if (userInput.length > 0) {
        el.value = `(${zip}`
      }
    },
    parseReportName,
    debounce,
    parseYearOverride,
    validatePassword (newPassword, confirmPassword, siteSettings, twoFields = true) {
      if (!siteSettings || !siteSettings.minLength) {
        siteSettings = {
          minLength: 0,
          minAlphaUpper: 0,
          minAlphaLower: 0,
          minNumeric: 0,
          minSymbol: 0,
          minUnique: 0
        }
      }
      let matchPassword = newPassword === confirmPassword
      if (!newPassword || newPassword.length === 0) {
        return newPasswordState(siteSettings, matchPassword, false, false, false, false, false)
      }
      let minLength = newPassword.length >= siteSettings.minLength

      let numUpper = (newPassword.match(/[A-Z]/g) || []).length
      let minAlphaUpper = numUpper >= siteSettings.minAlphaUpper

      let numLower = (newPassword.match(/[a-z]/g) || []).length
      let minAlphaLower = numLower >= siteSettings.minAlphaLower

      let numNumeric = (newPassword.match(/[0-9]/g) || []).length
      let minNumeric = numNumeric >= siteSettings.minNumeric
      let numSymbol = (
        newPassword.match(/[-@#\\!$%^&*()_+|~=`{}[\]:";'<>?,./]/g) || []
      ).length
      let minSymbol = numSymbol >= siteSettings.minSymbol

      let charMap = new Map()
      for (var i = 0; i < newPassword.length; i++) {
        charMap.set(newPassword[i], true)
      }
      let numUniq = charMap.size
      let minUniq = numUniq >= siteSettings.minUnique

      return newPasswordState(siteSettings, matchPassword, minLength, minAlphaLower, minAlphaUpper, minNumeric, minSymbol, minUniq, twoFields)
    },
    async getSiteSettings () {
      try {
        let route = `/sites/settings`
        let response = await this.axios.get(route)
        this.siteSettings = response.data
      } catch (error) {
        this.handleApiErr(error)
      } finally { }
    },
    checkPage (dataFn) {
      if (this.$route.query && this.$route.query.page) {
        let page = this.$route.query.page
        this.goToPage(page, dataFn)
        return
      }
      this.goToPage(1, dataFn)
    },
    async goToPage (page, dataFn, disabled = false) {
      if (disabled) {
        // hack to deal with disabled next/from buttons
        return
      }
      if (!page || page < 1) {
        page = 1
      }
      this.page = page
      let q = {
        query: Object.assign({}, this.$route.query, {
          page: page
        })
      }

      this.$router.push(q).catch(err => { this.handleApiErr(err, null, null, true) })
      await dataFn()
    },
    openTab (tabName) {
      let i, x, tablinks
      x = document.getElementsByClassName('content-tab')
      for (i = 0; i < x.length; i++) {
        x[i].style.display = 'none'
      }
      tablinks = document.getElementsByClassName('tab')
      for (i = 0; i < x.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(' is-active', '')
      }
      document.getElementById(tabName).style.display = 'block'
      // evt.currentTarget.className += ' is-active'
    },
    async loadModel (route, idInRoute, deleteId = true) {
      if (!route || route.trim().length < 1) {
        this.errorToast(`DEV: this.loadModel ${route} is missing`)
        return
      }
      try {
        this.isLoading = true
        route = trim(route, '/')
        route = `/${route}/`
        if (!idInRoute) {
          let id = this.$route.params.id
          route = route = `${route}${id}`
        }
        let response = await this.$http.get(route)
        this.setupForm(response.data, deleteId)
        return response.data
      } catch (error) {
        this.handleApiErr(error)
      } finally {
        this.isLoading = false
      }
    },
    resetForm () {
      Object.assign(this.input, this.inputDefault)
    },
    setupForm (model, deleteId = true) {
      Object.assign(this.input, model)
      this.inputDefault = model
    },
    async handleSubmit (route, nav, idInRoute = false, deleteId = true, msgPassThrough = undefined) {
      if (!route || !route.trim || route.trim().length < 1) {
        this.errorToast(`DEV: this.handleSubmit ${route} parameter is missing`)
        return
      }
      try {
        route = trim(route, '/')
        route = `/${route}/`
        let response = {}
        let payload = {
          ...this.input
        }

        if (this.isCreateMode) {
          this.isLoading = true
          response = await this.axios.post(route, payload)
        } else {
          let id
          if (!idInRoute && this.input.id) {
            id = this.input.id
          }
          if (typeof this.inputDefault === 'string') {
            this.inputDefault = JSON.parse(this.inputDefault)
            if (!id) {
              id = this.inputDefault.id
            }
          }
          if (id) {
            route = route + id
          }
          response = await this.axios.put(route, payload)
        }
        this.setupForm(response.data)
        this.successToast('Saved!', nav)
        return response.data
      } catch (error) {
        this.handleApiErr(error, undefined, msgPassThrough)
      } finally {
        this.isLoading = false
      }
    },
    successToast (msg, nav = undefined, queue = true, duration = TOAST_SUCCESS_TIME) {
      this.toast(msg, 'is-success', 'is-top', duration, nav, queue)
    },
    success (msg) {
      this.toast(msg, 'is-success', 'is-top', TOAST_SUCCESS_TIME)
    },
    error (msg) {
      this.toast(msg, 'is-warning', 'is-top', TOAST_ERROR_TIME)
    },
    errorToast (msg, nav = undefined, queue = true, duration = TOAST_ERROR_TIME) {
      this.toast(msg, 'is-warning', 'is-top', duration, nav, queue)
    },
    toast (msg, type, position, duration = 10000, nav = undefined, queue = true) {
      let secondThreshold = 500 // ms
      if (
        toastDedupe.msg !== msg ||
        Date.now() - toastDedupe.timestamp > secondThreshold
      ) {
        let message = {
          message: msg,
          type: type,
          position: position,
          duration: duration,
          queue: queue
        }
        Toast.open(message)
      }
      toastDedupe.timestamp = Date.now()
      toastDedupe.msg = msg
      if (nav && Number(nav)) {
        this.$router.go(nav)
      } else if (nav) {
        this.$router.push(nav).catch(err => { this.handleApiErr(err, null, null, true) })
      }
    },
    getDocumentPath (__document) {
      if (!__document || !__document.path || __document.path === undefined) {
        return ''
      }

      let path = __document.path

      // If the url contains clients but is not a client document then update the path
      if (path.includes('/clients') && (__document.typeCode !== 'USR')) {
        path = path.replace('api/documents/clients/', 'api/documents/')
      } else if (!path.includes('/clients') && __document.typeCode === 'USR') {
        // path = path.replace('api/documents/', 'api/documents/clients/')
      }

      return path
    },
    getFileSize (fileSize) {
      let label = 'b'
      // let number = 0
      // let round = 0

      while (fileSize / 1024 > 1) {
        fileSize = fileSize / 1024
        label = label === 'b' ? 'kb' : label === 'kb' ? 'mb' : label === 'mb' ? 'gb' : label === 'gb' ? 'tb' : label === 'tb' ? 'pb' : label === 'pb' ? 'eb' : label === 'eb' ? 'zb' : label === 'zb' ? 'yb' : label === 'yb' ? 'bb' : label === 'bb' ? 'cb' : label === 'cb' ? 'db' : label === 'db' ? 'eb' : label === 'eb' ? 'zb' : label === 'zb' ? 'yb' : label === 'yb' ? 'bb' : label === 'bb' ? 'cb' : label === 'cb' ? 'db' : label === 'db' ? 'eb' : label === 'eb' ? 'zb' : label === 'zb' ? 'yb' : label === 'yb' ? 'bb' : label === 'bb' ? 'cb' : label === 'cb' ? 'db' : label === 'db' ? 'eb' : label === 'eb' ? 'zb' : label === 'zb' ? 'yb' : label === 'yb' ? 'bb' : label === 'bb' ? 'cb' : label === 'cb' ? 'db' : label === 'db' ? 'eb' : label === 'eb' ? 'zb' : label === 'zb' ? 'yb' : label === 'yb' ? 'bb' : label === 'bb' ? 'cb' : label === 'cb' ? 'db' : label === 'db' ? 'eb' : label === 'eb' ? 'zb' : label === 'zb' ? 'yb' : label === 'yb' ? 'bb' : label === 'bb' ? 'cb' : label === 'cb' ? 'db' : label === 'db' ? 'eb' : label === 'eb' ? 'zb' : label === 'zb' ? 'yb' : label === 'yb' ? 'bb' : label === 'bb' ? 'cb' : label === 'cb' ? 'db' : label
      }

      return Intl.NumberFormat().format((fileSize).toFixed(0)) + ` ${label.toUpperCase()}`
    },
    confirmDelete (event) {
      if (this.hasSelection) this.showDeleteModal = true
    },
    async deleteSelected (route, paramName, msgPassThrough, callback = () => {}) {
      let paramNameLocal = 'ids'
      if (paramName) paramNameLocal = paramName

      if (!route || !route.trim || route.trim().length < 1) {
        this.errorToast(`DEV: this.handleSubmit ${route} parameter is missing`)
        return
      }
      this.showDeleteModal = false
      this.confirmationText = ''

      let request = {
        params: {}
      }
      request.params[paramNameLocal] = this.selectedRows

      try {
        await this.$http.delete(route, request)
        await this.refreshData()
        this.successToast('Deleted!')
        callback()
      } catch (err) {
        this.handleApiErr(err, undefined, msgPassThrough)
      }
    },
    // TODO - See if we can move this to be a global axios interceptor
    // This logic is likely something that we should apply to all API route calls
    // and apply the route exceptions uniformally.
    //
    // Brent - 10/5/2023
    handleApiErr (error, nav, msgPassThrough, ignoreToast = false) {
      let msg = error
      if (ignoreToast) {
        return
      }

      if (error.response) {
        if (error.response.status && error.response.status === 500) {
          if (!msgPassThrough) {
            msgPassThrough = 'Unable to complete operation. Please contact system administrator.'
          }
          this.errorToast(msgPassThrough)
          return
        }
        if (error.response.status && error.response.status === 401 && this.$route.name !== 'Login' && this.$route.name !== 'ChangePassword') {
          try {
            let route = `/session/logout`
            this.axios.post(route)
          } catch (error) {
            this.handleApiErr(error)
          } finally {
            this[logout]()
            this.isLoading = false
            this.$router.push({ name: 'Login' }).then(_ => window.location.reload()).catch(err => { this.handleApiErr(err, null, null, true) })
          }
          this.errorToast('Your session has expired. Please login again.')
          return
        } // redirect and logout handled by axios response interceptor
        if (error.response.status && error.response.status === 401) {
          return
        }
        msg = `Error: ${error.response.status}: ${error.response.statusText}`

        if (error.response.data && error.response.data.message) {
          msg = `Error: ${error.response.data.message}`

          if (error.response.data.isInputError) {
            msg = `Input Error: ${error.response.data.message}`
          }

          if (
            error.response.data.meta &&
            Object.entries(error.response.data.meta).length !== 0
          ) {
            // TODO: format better
            msg += ` meta:${JSON.stringify(error.response.data.meta)}`
          }
        }
      }
      this.errorToast(msg, nav, true)
    }
  }
})

Vue.config.productionTip = false
Vue.use(VueAxios, Axios)
Vue.use(VeeValidate)

if (process.env.VUE_APP_ENABLE_MOCK) {
  MockAdapter(Axios)
}
Vue.component('vue-headful', vueHeadful)

// adds `v-focus` directive to give inputs focus when displayed
Vue.directive('focus', {
  inserted: function (el) {
    el.focus()
  }
})

Vue.directive('clickoutside', {
  inserted: (el, binding, vnode) => {
    // assign event to the element
    el.clickOutsideEvent = function (event) {
      // here we check if the click event is outside the element and it's children
      if (!(el === event.target || el.contains(event.target))) {
        // if clicked outside, call the provided method
        vnode.context[binding.expression](event)
      }
    }
    // register click and touch events
    document.body.addEventListener('click', el.clickOutsideEvent)
    document.body.addEventListener('touchstart', el.clickOutsideEvent)
  },
  unbind: function (el) {
    // unregister click and touch events before the element is unmounted
    document.body.removeEventListener('click', el.clickOutsideEvent)
    document.body.removeEventListener('touchstart', el.clickOutsideEvent)
  },
  stopProp (event) {
    event.stopPropagation()
  }
})

new Vue({
  created () {
    store.dispatch('updateActiveSite')
  },
  render: h => h(App),
  store,
  router: Router
}).$mount('#app')
