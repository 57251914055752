import Axios from 'axios'
import qs from 'qs'
import router from './router'
import { store } from './store'
import { logout, updateSessionTimeout } from './vuex-actions'

const instance = Axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true,
  headers: { Accept: 'application/json' },
  paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' })
})

instance.interceptors.response.use((response) => {
  if (response && response.status >= 200 && response.status < 300 && store.getters.isLoggedIn) {
    store.commit(updateSessionTimeout)
  }
  return response
}, (error) => {
  if (error.response) {
    const { status } = error.response
    if (status === 401 && router.history) {
      store.commit(logout)
      let nav = ({ name: 'Login' })
      if (router.history.current.name !== 'Login' && router.history.current.name !== 'ChangePassword') {
        nav.query = { fromPath: router.history.current.fullPath }
        router.push(nav).then(_ => window.location.reload())
      }
      return Promise.resolve(JSON.stringify({ status, response: error.response }))
    }
    // not found are valid returns, not always an error
    if (status === 400) { return Promise.resolve(JSON.stringify({ status, response: error.response })) }
  }
  return Promise.reject(error)
})

export default instance
