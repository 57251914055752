var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.menuItem.isStatic
    ? _c(
        "div",
        {
          ref: _vm.menuItem.id,
          staticClass: "sidemenu-mask-dropdown-list",
          staticStyle: { "padding-left": "0rem !important" },
          attrs: { open: "open", level: _vm.nextLevel },
        },
        _vm._l(_vm.menuItemReportsByNameDesc, function (report) {
          return _c("div", { key: report.id }, [
            _c(
              "div",
              {
                staticClass: "sidemenu-mask-item",
                attrs: { type: report.typeCode },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return function ($event) {
                      return _vm.getReport(report)
                    }.apply(null, arguments)
                  },
                  mouseover: function ($event) {
                    return _vm.ellipsis($event, true)
                  },
                  mouseleave: function ($event) {
                    return _vm.ellipsis($event, false)
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: {
                          content: report.displayName,
                          trigger: "manual",
                        },
                        expression:
                          "{\n          content: report.displayName,\n          trigger: 'manual'\n        }",
                      },
                    ],
                    staticClass: "tooltip-hover",
                    attrs: { open: false },
                  },
                  [
                    _c("i", { staticClass: "fas fa-file item" }),
                    _vm._v(" " + _vm._s(report.displayName) + " "),
                  ]
                ),
              ]
            ),
          ])
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }