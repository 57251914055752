var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.activeLayout === "osr-facing" || _vm.activeLayout === "client-facing"
      ? _c("div", [
          _c(
            "div",
            {
              staticClass: "client has-background-white",
              staticStyle: { padding: "10px 25px" },
              attrs: { id: "footer-area" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "is-size-7-mobile",
                  staticStyle: { width: "100px" },
                  attrs: { id: "footer-text" },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "has-text-dark",
                      staticStyle: { "font-size": "13px!important" },
                    },
                    [
                      _vm._v(
                        " © " +
                          _vm._s(_vm._f("moment")(new Date(), "YYYY")) +
                          " "
                      ),
                    ]
                  ),
                ]
              ),
              _c("div", { staticClass: "is-flex" }, [
                _c(
                  "p",
                  {
                    staticClass:
                      "is-size-7 has-text-weight-normal has-text-dark mr-2",
                  },
                  [_vm._v(_vm._s(_vm.environment))]
                ),
                _c(
                  "p",
                  {
                    staticClass:
                      "is-size-7 has-text-weight-normal has-text-dark",
                  },
                  [_vm._v(_vm._s(_vm.version))]
                ),
              ]),
              _c("div", { attrs: { id: "is-powered-by" } }, [
                _c("i", { staticStyle: { "font-size": "10px!important" } }, [
                  _vm._v("Powered by"),
                ]),
                _c("img", {
                  staticStyle: { "max-height": "40px", width: "auto" },
                  attrs: { src: _vm.activeSite.logoUrl },
                }),
              ]),
            ]
          ),
        ])
      : _vm._e(),
    _vm.activeLayout !== "osr-facing" && _vm.activeLayout !== "client-facing"
      ? _c("div", [
          _c(
            "div",
            {
              staticClass: "admin has-background-primary",
              attrs: { id: "footer-area" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "has-text-centered is-size-7-mobile",
                  attrs: { id: "footer-text" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "is-flex is-justify-content-center has-text-centered is-flex-grow-1",
                    },
                    [
                      _c("p", { staticStyle: { padding: "15px" } }, [
                        _c("span", { staticClass: "has-text-white" }, [
                          _vm._v(
                            " © " +
                              _vm._s(_vm._f("moment")(new Date(), "YYYY")) +
                              " " +
                              _vm._s(_vm.activeSite.displayName) +
                              " "
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _c("div", { staticClass: "is-flex" }, [
                    _c(
                      "p",
                      {
                        staticClass:
                          "is-size-7 has-text-weight-normal has-text-dark mr-2",
                      },
                      [_vm._v(_vm._s(_vm.environment))]
                    ),
                    _c(
                      "p",
                      {
                        staticClass:
                          "is-size-7 has-text-weight-normal has-text-dark",
                      },
                      [_vm._v(_vm._s(_vm.version))]
                    ),
                  ]),
                ]
              ),
            ]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }