var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-vue", attrs: { id: "app" } },
    [
      _c("vue-headful", { attrs: { title: null } }),
      _c(
        "div",
        { attrs: { id: "app-area" } },
        [
          _vm.isLoggedIn &&
          ![null, "Login", "2FASetup", "2FAVerify", "ChangePassword"].includes(
            _vm.$route.name
          )
            ? _c("Header", { attrs: { activeLayout: this.activeLayout } })
            : _vm._e(),
          _vm._v(" " + _vm._s(_vm.isFullScreen) + " "),
          _c(
            "div",
            {
              class: {
                loggedout:
                  !_vm.isLoggedIn &&
                  _vm.$route.name !== "ChangePassword" &&
                  ![null, "Login", "2FASetup", "2FAVerify"].includes(
                    _vm.$route.name
                  ),
              },
              attrs: {
                id:
                  _vm.isLoggedIn &&
                  _vm.$route.name !== "ChangePassword" &&
                  ![null, "Login", "2FASetup", "2FAVerify"].includes(
                    _vm.$route.name
                  )
                    ? _vm.isFullScreen
                      ? "app-body-area-cp"
                      : "app-body-area"
                    : "app-body-area-cp",
              },
            },
            [
              _vm.isLoggedIn &&
              _vm.$route.name !== "ChangePassword" &&
              ![null, "Login", "2FASetup", "2FAVerify"].includes(
                _vm.$route.name
              )
                ? _c("Links", {
                    attrs: { activeLayout: this.activeLayout },
                    on: {
                      isOpen: function ($event) {
                        _vm.sideNavisOpen = $event
                      },
                    },
                  })
                : _vm._e(),
              _c(
                "div",
                {
                  attrs: {
                    sideNavisOpen:
                      !_vm.isFullScreen &&
                      _vm.sideNavisOpen &&
                      _vm.isLoggedIn &&
                      _vm.$route.name !== "ChangePassword" &&
                      ![null, "Login", "2FASetup", "2FAVerify"].includes(
                        _vm.$route.name
                      ),
                    id:
                      (_vm.activeLayout == "dev" ||
                        _vm.activeLayout == "administrative") &&
                      ![
                        null,
                        "Login",
                        "2FASetup",
                        "2FAVerify",
                        "ChangePassword",
                      ].includes(_vm.$route.name)
                        ? _vm.isFullScreen
                          ? "content-area-full"
                          : "content-area"
                        : "content-area-full",
                  },
                },
                [_c("Target")],
                1
              ),
            ],
            1
          ),
          _c("Footer", { attrs: { activeLayout: this.activeLayout } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }