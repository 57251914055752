var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.show
      ? _c(
          "div",
          {
            staticClass: "sidemenu-mask-dropdown-list carrier",
            attrs: { level: _vm.level, open: true },
          },
          [
            _c(
              "div",
              {
                ref: _vm.item.code,
                staticClass: "sidemenu-mask-dropdown",
                attrs: { id: _vm.item.code, children: _vm.childCount },
                on: {
                  click: function ($event) {
                    _vm.isOpen = !_vm.isOpen
                  },
                  mouseover: function ($event) {
                    return _vm.ellipsis($event, true)
                  },
                  mouseleave: function ($event) {
                    return _vm.ellipsis($event, false)
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip.top-center",
                        value: {
                          content: _vm.item.name,
                          trigger: "manual",
                          boundariesElement: _vm.sidemenu,
                        },
                        expression:
                          "{ content: item.name, trigger: 'manual', boundariesElement: sidemenu }",
                        modifiers: { "top-center": true },
                      },
                    ],
                    staticClass: "tooltip-hover",
                  },
                  [
                    _c("i", {
                      class: [
                        "status-icon fa fa-chevron-right",
                        _vm.isOpen ? "open" : "",
                      ],
                    }),
                    !_vm.isOpen
                      ? _c("i", { staticClass: "fa fa-folder" })
                      : _vm._e(),
                    _vm.isOpen
                      ? _c("i", { staticClass: "fa fa-folder-open" })
                      : _vm._e(),
                    _vm._v(" " + _vm._s(_vm.item.name) + " "),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              {
                ref: _vm.item.code,
                staticClass: "sidemenu-mask-dropdown-list",
                attrs: {
                  "data-controlled-by": _vm.item.code,
                  open: _vm.isOpen,
                  level: _vm.getNextLevel(),
                  type: _vm.item.code,
                },
              },
              _vm._l(_vm.templates, function (child) {
                return _c(
                  "div",
                  { key: child.id },
                  [
                    _c("MenuItem", {
                      attrs: {
                        item: child,
                        activeSite: _vm.activeSite,
                        activeUser: _vm.activeUser,
                        reportTypeCodes: _vm.reportTypeCodes,
                        level: _vm.getNextLevel(),
                        carriers: _vm.carriers,
                        open: _vm.isOpen,
                        reportCodeCounts: _vm.reportCodeCounts,
                        carrierCode: child.carrierCode,
                      },
                      on: { years: _vm.onYearsEvent },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }