var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        "header--container": true,
        "is-open": _vm.isOpen,
        "is-closed": !_vm.isOpen,
      },
    },
    [
      _vm.activeLayout === "osr-facing" || _vm.activeLayout === "client-facing"
        ? _c(
            "div",
            { staticStyle: { "max-width": "100vw", overflow: "hidden" } },
            [
              _vm.activeLayout === "osr-facing"
                ? _c("div", [
                    _vm.activeLayout === "osr-facing"
                      ? _c(
                          "div",
                          {
                            staticClass: "osr-facing",
                            attrs: { id: "institution-switcher" },
                          },
                          [
                            _c(
                              "span",
                              { attrs: { id: "switch-institutions" } },
                              [_vm._v("Switch institutions: ")]
                            ),
                            _vm.options.length > 0 && _vm.activeInstitution
                              ? _c("Dropdown", {
                                  attrs: {
                                    options: _vm.options,
                                    maxItem: 1000,
                                    defaultItem: _vm.activeInstitution,
                                  },
                                  on: {
                                    selected: function (option) {
                                      return _vm.setActiveInstitutionId(
                                        option.id
                                      )
                                    },
                                    filter: function () {
                                      return null
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "osr client has-background-white",
                  attrs: { id: "header-area" },
                },
                [
                  _c("div", { attrs: { id: "header-left" } }, [
                    _vm._v(" Welcome, "),
                    _c("b", { staticStyle: { "margin-left": ".5rem" } }, [
                      _c("i", [
                        _vm._v(
                          _vm._s(_vm.user.firstName) +
                            " " +
                            _vm._s(_vm.user.lastName)
                        ),
                      ]),
                    ]),
                  ]),
                  _c("div", { attrs: { id: "header-center" } }, [
                    _c("span", [
                      _c("b", [
                        _vm._v(
                          _vm._s(_vm.getInstitutionsName()) +
                            " " +
                            _vm._s(
                              _vm.activeSite.id === 1
                                ? "BOLI Portal"
                                : "COLI Portal"
                            )
                        ),
                      ]),
                    ]),
                  ]),
                  _c("div", { attrs: { id: "header-right" } }, [
                    _c("img", {
                      staticStyle: {
                        "max-height": "75px",
                        "max-width": "280px",
                        width: "auto",
                        "margin-top": "-12px",
                      },
                      attrs: { src: _vm.logo },
                    }),
                  ]),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm.activeLayout === "administrative"
        ? _c("div", [
            _c(
              "div",
              {
                staticClass: "has-background-white",
                attrs: { id: "header-area" },
              },
              [
                _c(
                  "div",
                  { staticClass: "flex", attrs: { id: "header-left" } },
                  [
                    _c(
                      "h2",
                      {
                        staticStyle: {
                          "font-size": "1.2rem",
                          "font-weight": "600",
                        },
                      },
                      [
                        _vm._v(
                          "Welcome, " +
                            _vm._s(_vm.user.firstName) +
                            " " +
                            _vm._s(_vm.user.lastName)
                        ),
                      ]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticStyle: { "text-align": "center" },
                    attrs: { id: "header-center" },
                  },
                  [
                    _vm.sites && _vm.sites.length > 0
                      ? _c(
                          "div",
                          {
                            staticClass: "admin-facing",
                            attrs: { id: "institution-switcher" },
                          },
                          [
                            _c(
                              "div",
                              { attrs: { id: "switch-institutions" } },
                              [_vm._v("Active Site:")]
                            ),
                            _vm.sites.length > 0 && _vm.activeSite.id
                              ? _c("Dropdown", {
                                  attrs: {
                                    options: _vm.sites,
                                    maxItem: 1000,
                                    defaultItem: {
                                      id: _vm.activeSite.id,
                                      name: _vm.activeSite.name,
                                    },
                                  },
                                  on: {
                                    selected: function (option) {
                                      return _vm.setActiveSiteId(option.id)
                                    },
                                    filter: function () {
                                      return null
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
                _c("div", { attrs: { id: "header-right" } }, [
                  _c(
                    "div",
                    { staticClass: "flex", attrs: { id: "header-logo" } },
                    [
                      _c("img", {
                        staticStyle: { "max-height": "70px" },
                        attrs: { src: _vm.activeSite.logoUrl },
                      }),
                    ]
                  ),
                ]),
              ]
            ),
          ])
        : _vm._e(),
      !_vm.isFullScreen &&
      (_vm.activeLayout === "osr-facing" ||
        _vm.activeLayout === "client-facing")
        ? _c("sidebar-menu", {
            attrs: {
              activeLayout: _vm.activeLayout,
              activeSite: _vm.activeSite,
              activeUser: _vm.user,
            },
            on: {
              openChanged: function ($event) {
                return (_vm.isOpen = $event)
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }