var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sidemenu-mask-dropdown-list",
      staticStyle: { "padding-left": "2.2rem !important" },
      attrs: { open: "open", level: _vm.level === 0 ? 2 : _vm.level - 1 },
    },
    [_c("div", { staticClass: "sidemenu-mask-item" }, [_c("spin-loader")], 1)]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }